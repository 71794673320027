<template>
  <a-drawer width='35%' :label-col='4' :wrapper-col='14' :visible='open' @close='onClose'>
    <a-divider orientation='left'>
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref='form' :model='form' :rules='rules'>
      <a-form-model-item label='标题' prop='title' :required="true">
        <a-input v-model='form.title' placeholder='请输入标题(最长15字)' :maxlength="15"/>
      </a-form-model-item>
      <a-form-model-item label="位置类型" prop='type' :required="true">
        <a-select placeholder="请选择" v-model="form.type" >
          <a-select-option :value="1">首页</a-select-option>
          <a-select-option :value="2">分类</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label='内容' prop='content' :required="true">
        <a-textarea v-model='form.content' :rows='3' showCount :maxlength="80"/>
      </a-form-model-item>
      <a-form-model-item label='图片地址' prop='imgUrl' :required="true">
        <a-upload   listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => fileBeforeUpload(info)'
                  :customRequest='(info) => fileUploadRequest(info)' v-model="form.imgUrl">
          <img v-if="form.imgUrl != null" :src='form.imgUrl' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>
        </a-upload>
<!--        <a-input v-model='form.imgUrl' placeholder='请输入图片地址' />-->
      </a-form-model-item>
      <a-form-model-item label='链接类型' prop='linkType' :required="true">
        <a-select placeholder="请选择" v-model="form.linkType" @change="handleLinkType">
          <a-select-option :value="1">不跳转</a-select-option>
          <a-select-option :value="2">外链</a-select-option>
          <a-select-option :value="3">歌曲</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-item
        v-if="form.linkType"
        :label="form.linkType==2?'外部链接':(form.linkType==3?'动态曲谱':'')"
        :required="true">
        <div v-if="form.linkType==3">
          <a-button type="primary" @click="selectGraph">
            <span v-if="graphName">已添加 {{ graphName }}</span>
            <span v-if="!graphName">添加</span>
          </a-button>
        </div>
        <a-input v-if="form.linkType==2" v-model="form.link" placeholder="请输入外部链接"/>
      </a-form-item>
<!--      <a-form-model-item label='与link_type结合使用，当type等于2时为外链地址，3，4为商品ID，1为空' prop='link'>-->
<!--        <a-input v-model='form.link'-->
<!--                 placeholder='请输入与link_type结合使用，当type等于2时为外链地址，3，4为商品ID，1为空' />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label='是否启用' prop='useFlag'>
        <switch-pack  v-model="form.useFlag"></switch-pack>
<!--        <a-input v-model='form.useFlag' placeholder='请输入是否有用：0-不启用 1-启用' />-->
      </a-form-model-item>
      <div class='bottom-control'>
        <a-space>
          <a-button type='primary' :loading='submitLoading' @click='submitForm'>
            保存
          </a-button>
          <a-button type='dashed' @click='cancel'>
            取消
          </a-button>
        </a-space>
      </div>
      <graph-list ref="graphList" @ok="modalFormOk"></graph-list>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getBanner, addBanner, updateBanner } from '@/api/banner/banner'
import Editor from '@/components/Editor'
import SwitchPack from '@/components/crud/form/SwitchPack.vue'
import GraphList from './GraphList'
import { getGraphDetail } from '@/api/dynamic/graphDetail'
import { Log } from '@/components/crud/Log'
import UploadFileToOSS from '@/utils/upload-file-to-oss'
export default {
  name: 'CreateForm',
  props: {},
  components: {
    SwitchPack,
    Editor,
    GraphList
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        type: null,
        title: null,
        content: null,
        imgUrl: null,
        linkType: null,
        link: null,
        useFlag: null,
        createTime: null
      },
      graph: {},
      graphName: '',
      // 1增加,2修改
      formType: 1,
      open: false,
      loading: false,
      rules: {}
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.graph = {};
      this.graphName = ''
      this.open = false
    },
    handleLinkType (type) {
      // this.restContent()
      this.form.linkType = type
    },
    restContent () {
      this.form.content = ''
    },
    selectGraph () {
      let type = this.form.linkType
      this.$refs.graphList.visible = true
      this.$refs.graphList.graph = this.graph
    },
    getGraphInfo (id) {
      getGraphDetail({ 'id': id }).then(res => {
        this.graph = res.data
        this.graphName = this.graph.title
        let str = this.graph.title
        if (str.length > 8) {
          this.graphName = str.substring(0, 8) + '...'
        } else {
          this.graphName = str
        }
      })
    },
    modalFormOk () {
      this.graph = this.$refs.graphList.graph
      let str = this.graph.title
      if (str && str.length > 8) {
        this.graphName = str.substring(0, 8) + '...'
      } else {
        this.graphName = str
      }
      console.log(this.graph)
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        title: null,
        content: null,
        imgUrl: null,
        linkType: null,
        link: null,
        useFlag: null,
        createTime: null,
        remark: null
      }
      this.graph = {};
      this.graphName = ''
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBanner({ 'id': id }).then(response => {
        this.form = response.data
        this.formTitle = '修改'
        debugger
        if (this.form.linkType == 3) {
          this.getGraphInfo(this.form.link)
        }
        this.open = true
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      debugger
      if(this.form.linkType == 3){
        this.form.link = this.graph.id;
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBanner(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBanner(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(fileInfo) {
      // 图片类型字段, 进行文件类型判断
      if (!fileInfo.type.includes('image')) {
        this.$message.warning('请上传图片');
        return false;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(fileInfo) {
      Log.info('uploadfileInfo', fileInfo);

      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          Log.info('upload', res);
          this.loading = false;
          this.form.imgUrl = res[0].url;
        },
      });
    },
  }
}
</script>
